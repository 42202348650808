/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import JobPostPayment from "../../components/Payment/JobPostPayment";
import { connect } from "react-redux";
import PricingSection from "../../components/pricing/PricingSection";
import { ROUTES } from "../../helpers/RouteHelper";
import { toastWarning } from "../../helpers/CommonHelper";

const defaultProps = {};

const propTypes = {};

const mapStateToProps = ({ app }) => ({
  Email: app?.Email,
  IsPostingAllowed: app?.IsPostingAllowed,
});

const PaymentPage = connect(
  mapStateToProps,
  null
)(({ Email, IsPostingAllowed }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [priceParam] = useState(
    () => searchParams.get("selectedPackage") || ""
  );

  const [step, setStep] = useState(1);
  const [selectedPricing, setSelectedPricing] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    if (IsPostingAllowed === true) {
      toastWarning("You already have an active package");
      navigate(ROUTES.DASHBOARD_PROFILE);
    }
  }, [IsPostingAllowed, navigate]);

  return (
    <>
      <div className="container mb-5">
        <div className="card mb-3 mt-4">
          <div className="card-body mt-4">
            <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
              <div className={step > 0 ? "step completed" : "step"}>
                <div className="step-icon-wrap">
                  <div className="step-icon">
                    <i className="fa-solid fa-money-check-dollar" />
                  </div>
                </div>
                <h4 className="step-title">Select Package</h4>
              </div>
              <div className={step > 1 ? "step completed" : "step"}>
                <div className="step-icon-wrap">
                  <div className="step-icon">
                    <i className="fa-solid fa-circle-info" />
                  </div>
                </div>
                <h4 className="step-title">Payment</h4>
              </div>
            </div>
          </div>
        </div>
        <div>
          {step === 1 && (
            <PricingSection
              enableSelect
              priceParam={priceParam}
              selectedPricing={selectedPricing}
              setSelectedPricing={setSelectedPricing}
            />
          )}
          {step === 2 && (
            <JobPostPayment email={Email} selectedPricing={selectedPricing} />
          )}
        </div>
        <div className="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-sm-between align-items-center mt-4">
          <div className="custom-control custom-checkbox mr-3 m-1">
            {[1].includes(step) && (
              <button
                type="button"
                className="btn btn-outline-primary btn-rounded btn-lg"
                onClick={() => setStep(step - 1)}
              >
                Previous
              </button>
            )}
          </div>
          {[1].includes(step) && (
            <div className="text-left text-lg-right">
              <button
                type="button"
                className="btn btn-outline-primary btn-rounded btn-lg  m-1"
                onClick={() => setStep(step + 1)}
                disabled={step === 1 && !selectedPricing}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

PaymentPage.propTypes = propTypes;
PaymentPage.defaultProps = defaultProps;

export default PaymentPage;
