/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useField, useFormikContext } from "formik";
import { useGetPricing } from "../../services/usePricing";
import PricingCardFour from "./Cards/PricingCardFour";
import PricingCardOne from "./cards/PricingCardOne";
import PricingCardTwo from "./Cards/PricingCardTwo";
import { PricingSkeleton } from "../Skeletons";
import { ROUTES } from "../../helpers/RouteHelper";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TENANT_CONFIG } from "../../config/tenant";

const PricingSection = ({
  enableSelect,
  selectedPricing,
  showCardOne,
  isHomePage,
  setSelectedPricing,
  priceParam,
  couponCodeList,
}) => {
  const { t } = useTranslation();
  const { isLoading, isError, data, error } = useGetPricing();

  useEffect(() => {
    // to preselect the pricing
    if (data && setSelectedPricing && priceParam) {
      setSelectedPricing(() => data?.find((x) => x.id === priceParam));
    }
  }, [data, setSelectedPricing, priceParam]);

  return (
    <>
      <section className="price-wrap pb-5 pt-sm-0 pt-md-5">
        <div className="container">
          <div className="row">
            {/* Title */}
            {enableSelect ? (
              <div className="col-xl-10 mx-auto text-center">
                <h6 style={{ color: 'var(--primary)' }}>Job packages</h6>
                <h1 className="mb-2">Select a Package</h1>
                <p className="lead mb-0">
                  Get benefit with our <strong>unlimited job packages</strong>{" "}
                </p>
              </div>
            ) : (
              <div className="col-xl-10 mx-auto">
                {/* <h2 className="text-primary text-center">Pricing</h2> */}
                <h1 className="mb-2 text-center">
                  {t("#packages-page.title")}
                </h1>
                <p className="mb-0 pt-1 pb-2">
                  {t("#packages-page.description-01")}
                </p>
                {!isHomePage && (
                  <>
                    {" "}
                    <p
                      className="mb-0 pt-1"
                      dangerouslySetInnerHTML={{
                        __html: t("#packages-page.description-02", {app_title: TENANT_CONFIG.AppTitle}),
                      }}
                    ></p>
                    <div className="col-xl-10 mx-auto pt-2">
                      <ul className="m-b30">
                        <li style={{ listStyle: "unset" }}>
                          {t("#packages-page.description-list.L0")}
                        </li>
                        <li style={{ listStyle: "unset" }}>
                          {t("#packages-page.description-list.L1")}
                        </li>
                        <li style={{ listStyle: "unset" }}>
                          {t("#packages-page.description-list.L2")}
                        </li>
                        <li style={{ listStyle: "unset" }}>
                          {t("#packages-page.description-list.L3")}
                        </li>
                        <li style={{ listStyle: "unset" }}>
                          {t("#packages-page.description-list.L4")}
                        </li>
                        <ol className="m-b30">
                          <li style={{ listStyle: "none" }}>
                            {t("#packages-page.description-list.sub-list.S1")}
                          </li>
                          {/* <li style={{ listStyle: "unset" }}>
                            You can pay via PayPal.
                          </li>
                          <li style={{ listStyle: "unset" }}>
                            If you want to pay by E-Transfer then please{" "}
                            <strong>
                              <Link to={ROUTES.CONTACT_US}>CONTACT US</Link>
                            </strong>
                            .
                          </li> */}
                        </ol>
                      </ul>
                    </div>
                    <h4 className="pt-2 text-center" style={{ color: 'var(--primary)' }}>
                      Get benefit with our{" "}
                      <strong>Unlimited Job Packages</strong>
                    </h4>
                    {couponCodeList?.length > 0  &&
                    <h5 className="pt-2 text-center" style={{ color: 'var(--primary)' }}>
                      Available discount coupons are{" "}
                      {couponCodeList?.map((coupon,i) => {
                        return <div key={i} className="p-1 shine text-danger">
                          {coupon?.label} : ({coupon?.code})
                        </div>
                      })}
                    </h5>}
                  </>
                )}
              </div>
            )}
          </div>{" "}
          {/* Row END */}
          {/* Pricing START */}
          <div className="row justify-content-center ">
            <section className="content-inner bg-light position-relative">
              <div className="container">
                <div className="row justify-content-center">
                  {isLoading ? (
                    [1, 2, 3].map((x) => (
                      <div className="col-xl-4 col-md-6" key={x}>
                        <PricingSkeleton />
                      </div>
                    ))
                  ) : isError ? (
                    <div>Error: {error.message}</div>
                  ) : (
                    data?.map((pricing) =>
                      enableSelect ? (
                        <div
                          key={pricing.id}
                          onClick={() => {
                            if (setSelectedPricing) {
                              setSelectedPricing(pricing);
                            }
                          }}
                          className={
                            pricing.id === selectedPricing?.id
                              ? "border border-success border-5 mb-2"
                              : "border border-secondary border-1 mb-2"
                          }
                        >
                          <PricingCardFour pricing={pricing} />
                        </div>
                      ) : showCardOne ? (
                        <PricingCardOne key={pricing.id} pricing={pricing} />
                      ) : (
                        <PricingCardTwo key={pricing.id} pricing={pricing} />
                      )
                    )
                  )}
                </div>
              </div>
            </section>
          </div>
          {/* Pricing END */}
        </div>
      </section>
    </>
  );
};

export default PricingSection;
