import axios from "axios";
import { useQuery } from "react-query";
import { API_URL } from "../config/env";

export const useGetPricing = () => {
  return useQuery(
    ["pricing-list"],
    () =>
      axios({
        method: "get",
        url: `${API_URL}/pricing/user/details?IsActive=true`,
        params: {},
      }),
    { select: (response) => response.data.results }
  );
};
