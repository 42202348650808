import { toast } from "react-toastify";
import { JOB_POST_FORM, JOB_VALIDATION_SCHEMA } from "../pages/jobs/jobHelper";
import { ROUTES } from "./RouteHelper";
import moment from "moment";

export const isRequiredField = (form, name) => {
  if (form === JOB_POST_FORM) {
    const tests = JOB_VALIDATION_SCHEMA.describe().fields[name];
    return !tests?.optional;
  }
  return false;
};

export const timeSince = (date) => {
  let seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
};

export const GetMasterList = (masterDataList, code, isValueAsDescription) => {
  if (!masterDataList) {
    return [];
  }
  const data = masterDataList
    ?.find((m) => m.Code === code)
    ?.MasterData?.map((x) => ({
      value: isValueAsDescription ? x?.Description : x?.id,
      label: x?.Label,
      code: x?.Code,
      IsActive: x?.IsActive,
    }))
    ?.sort((a, b) => a.Order - b.Order);
  return data;
};

export const getMasterIdToType = (masterList, id) =>
  masterList?.find((m) => m.value === id)?.code;

export const getMasterIdToLabel = (masterList, id) =>
  masterList?.find((m) => m.value === id)?.label;

export const validateEmail = (email) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

export const toastSuccess = (message = "Successfully submitted!") =>
  toast.success(message, {
    position: "bottom-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
  });
export const toastError = (message = "Some error occurred!") =>
  toast.error(message, {
    position: "bottom-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
  });
export const toastWarning = (message) =>
  toast.warn(message, {
    position: "bottom-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
  });

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const reloadScreen = () => {
  setTimeout(() => {
    window.open(ROUTES.HOME, "_self");
  }, 500);
};

export const isDateValid = (dateString) => {
  return new Date(dateString) >= new Date().setHours(0, 0, 0, 0);
};

export const GetRemoteConfig = (remoteAppConfigList, typeCd) => {
  return remoteAppConfigList?.find((x) => x.code === typeCd)?.value || "";
};

export const htmlToText = (html) => {
  var temp = document.createElement("div");
  temp.innerHTML = html;
  return temp.textContent; // Or return temp.innerText if you need to return only visible text. It's slower.
};

/**
 * generates a random number between start and end
 * @param {*} minimum Starting number
 * @param {*} maximum Ending number
 * @returns
 */
export const getRandomNumber = (minimum, maximum) =>
  Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;

/**
 * BruceWayneIsBatman => bruce wayne is batman
 */
export const amendSentence = (sentence, replaceWith = " ") => {
  let str = sentence.split("");
  let formatted = "";
  // Traverse the string
  for (let i = 0; i < str.length; i++) {
    // Convert to lowercase if its
    // an uppercase character
    if (
      str[i].charCodeAt() >= "A".charCodeAt() &&
      str[i].charCodeAt() <= "Z".charCodeAt()
    ) {
      // Print space before it
      // if its an uppercase
      // character
      if (i != 0) formatted = formatted + replaceWith;
      // Print the character
      formatted = formatted + str[i];
    }
    // if lowercase character
    // then just print
    else formatted = formatted + str[i];
  }
  return formatted;
};

export const isCommonWords = (first, second) => {
  let words1 = first.split(/\s+/g),
    words2 = second.split(/\s+/g),
    i,
    j;

  for (i = 0; i < words1.length; i++) {
    for (j = 0; j < words2.length; j++) {
      if (words1[i].toLowerCase() == words2[j].toLowerCase()) {
        return true
      }
    }
  }
  return false;
};
/**
 * Return date after days
 * @param {*} days 
 * @returns 
 */
export const dateAfterDays = (days) => {
  var result = new Date();
  result.setDate(result.getDate() + days);
  return moment(result).format("YYYY-MM-DD");
}