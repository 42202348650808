import { Modal } from "react-bootstrap";
import { useVerifyEmail, useVerifyResendOtp } from "../../services/useAuth";
import { connect, useDispatch } from "react-redux";
import { ROUTES } from "../../helpers/RouteHelper";
import { useState } from "react";
import {
  reloadScreen,
  toastError,
  toastSuccess,
} from "../../helpers/CommonHelper";
import { clearUser } from "../../store/reducers/appSlice/appSlice";
import { Link } from "react-router-dom";
import { APP_CODE } from "../../config/env";

const mapStateToProps = ({ app }) => ({
  Email: app?.Email,
  isAuthenticated: app?.isAuthenticated,
  IsBackendUser: app?.User?.IsBackendUser,
  IsEmailVerified: app?.User?.IsEmailVerified,
});

const VerifyEmail = connect(
  mapStateToProps,
  null
)(({ Email, IsEmailVerified }) => {
  const dispatch = useDispatch();
  const [otp, setOTP] = useState("");
  const [apiError, setApiError] = useState("");
  const { mutate, isLoading } = useVerifyEmail();
  const { mutate: mutateOTP, isLoading: isLoadingOTP } = useVerifyResendOtp();

  const onSubmit = () => {
    mutate(
      { otp: otp },
      {
        onSuccess: async (response) => {
          const data = await response.json();
          if ([200, 201].includes(response?.status)) {
            toastSuccess(data);
            reloadScreen();
          } else if (response?.status === 400) {
            setApiError(data);
            setOTP("");
          } else {
            toastError(data || "Retry Again, Some error occurred!");
          }
        },
        onError: () => {
          toastError("Retry Again, Some error occurred!");
        },
      }
    );
  };

  const ResendOtp = () => {
    mutateOTP(
      { username: Email },
      {
        onSuccess: async (response) => {
          const data = await response.json();
          if ([200, 201].includes(response?.status)) {
            toastSuccess(data || "Successfully reset password!");
          } else if (response?.status === 400) {
            setApiError(data);
          } else {
            toastError(data || "Retry Again, Some error occurred!");
          }
        },
        onError: () => {
          toastError("Retry Again, Some error occurred!");
        },
      }
    );
  };

  return (
    <Modal
      show={!IsEmailVerified}
      fullscreen
      size="xl"
      style={{ zIndex: 10000 }}
    >
      <Modal.Header>
        <Modal.Title>Verify Email to Continue</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container mb-5">
          <section className="vh-xxl-100 pt-5">
            <div className="container h-100 d-flex px-0 px-sm-4">
              <div className="row justify-content-center align-items-center m-auto">
                <div className="col-12">
                  <div className="bg-mode shadow rounded-3 overflow-hidden">
                    <div className="row g-0">
                      <div className="order-1">
                        <div className="p-4 p-sm-7">
                          <Link to={ROUTES.HOME}>
                            <img
                              className="mb-4 h-50px"
                              src={`/${APP_CODE}/Logo.svg`}
                              alt="logo"
                              style={{
                                width: "100px",
                              }}
                            />
                          </Link>
                          <h1 className="mb-2 h3">Verify Your Email?</h1>
                          <p className="mb-sm-0">
                            Enter the otp sent to your registered email address.
                          </p>
                          <div className="mt-sm-4 text-start">
                            <div className="mb-3">
                              <label className="form-label">
                                Registered Email id
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                disabled
                                value={Email}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label">Enter OTP</label>
                              <input
                                value={otp}
                                className="form-control"
                                onChange={(e) => {
                                  setOTP(e.target.value);
                                  setApiError("");
                                }}
                                type="number"
                              />
                            </div>
                            {apiError && (
                              <span className="text-danger">{apiError}</span>
                            )}
                            <div className="d-grid">
                              <button
                                onClick={onSubmit}
                                className="btn"
                                style={{
                                  background: "var(--primary)",
                                  color: "var(--primary-contrast)",
                                }}
                                disabled={isLoading || !otp}
                              >
                                {isLoading
                                  ? "Working on it, kindly wait..."
                                  : "Verify Email"}
                              </button>

                              <button
                                onClick={ResendOtp}
                                className="btn mt-2"
                                style={{
                                  background: "var(--primary)",
                                  color: "var(--primary-contrast)",
                                }}
                                disabled={isLoadingOTP}
                              >
                                {isLoadingOTP
                                  ? "Sending otp, kindly wait..."
                                  : "Resend OTP"}
                              </button>

                              <div className="mb-3 d-sm-flex justify-content-between">
                                <div>
                                  <label className="form-label">
                                    Didn't found the mail? Kindly check in your Spam list.
                                  </label>
                                </div>
                              </div>

                              <button
                                onClick={() => dispatch(clearUser())}
                                className="btn btn-danger mt-5"
                                disabled={isLoading}
                              >
                                LOGOUT
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default VerifyEmail;
